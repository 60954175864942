import axios from "axios";
import { Notify ,Toast} from 'vant';
import router from '@/router';
import { getRefreshToken } from '@/api/com'
function errorCreate(msg) {
    const error = new Error(msg)
    // errorLog(error)
    errorLog(msg.message)

    throw error
}
function errorCreate2(msg) {
    const error = new Error(msg)
    // errorLog(error)
    errorLog('系统异常,管理员正在抢修...')

    throw error
}
// 记录和显示错误
function errorLog(error) {
    // 显示提示
    Notify({
        message: error.message + '',
        type: 'danger',
        duration: 5 * 1000
    })
}

const service = axios.create({
    baseURL: 'https://cf.itsse.cn/api',
    // baseURL: 'http://192.168.3.25:17895/api',https://cf.itsse.cn/api
    // baseURL: 'http://192.168.3.3:17853/api',
    // baseURL: 'http://127.0.0.1:17853/api',
    // baseURL: 'http://127.0.0.1:17890/api',
    // baseURL: 'http://m.yunkuangjin.com:17895/api',
    // baseURL: 'https://api.ykj.icu/client/api',
    timeout: 50000 // 请求超时时间
})

let refreshTokenFlg = true;

function refreshToken() {
    if (!refreshTokenFlg) return false;
    let logoCtime = localStorage.getItem('.y-l-ctime');
    if (!logoCtime) return false;
    // 86400000 一天
    let newDate = Date.now();
    if (newDate - Number(logoCtime) > 172800000) {
        getRefreshToken()
            .then(res => {
                console.log('get refreshToken');
            })
            .catch(err => {
                console.log(err);
            })
        refreshTokenFlg = false;
        setTimeout(() => {
            refreshTokenFlg = true;
        }, 1000 * 60 * 10)
    }

}

// 请求拦截
service.interceptors.request.use(config => {
    // refreshToken();
    let token = localStorage.getItem('token')
    config.headers.authorization = `${token}`;
    Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
    return config;
});

/// 响应拦截器
service.interceptors.response.use(
    response => {
        Toast.clear();
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data
        // 这个状态码是和后端约定的
        const { code } = dataAxios
        // 根据 code 进行判断
        if (code === undefined) {
            return dataAxios
        } else {
            // 有 code 代表这是一个后端接口 可以进行进一步的判断
            switch (code) {
                case 0:
                    // [ 示例 ] code === 0 代表没有错误
                    return dataAxios
                case 1:
                    errorCreate(`${dataAxios.msg}`)
                    break
                case 401:
                    errorCreate2(`${dataAxios.msg}`)
                    break
                case 2001:
                    // 登录成功
                    localStorage.setItem('ykj-token', dataAxios.token);
                    localStorage.setItem('ykj-userInfo', JSON.stringify(dataAxios.userInfo));
                    // 金额是否隐藏 1 不隐藏 0 隐藏
                    localStorage.setItem('ykj-setting', JSON.stringify({ userPrice: true, BalancePrice: true }))
                    // router.back(-1)
                    // 设置登陆时间
                    localStorage.setItem('.y-l-ctime', Date.now())
                    router.push('/')
                    return true
                case 2002:
                    // 未登录[ 示例 ] 其它和后台约定的 code  token 错误
                    localStorage.removeItem('ykj-token')
                    router.push('/login')
                    // errorCreate(`[ code: 2002 - 登录过期 ] ${dataAxios.msg}: ${response.config.url}`)
                    errorCreate(`${dataAxios.msg}`)
                    break
                case 2003:
                    // 更新token
                    localStorage.setItem('ykj-token', dataAxios.token);
                    localStorage.setItem('.y-l-ctime', Date.now());
                    console.log('refreshToken success');
                    break
                case 4001:
                    // 文章 没有
                    errorCreate(`${dataAxios.msg}`)
                    break
                case 4002:
                    // 未实名认证
                    errorCreate(`${dataAxios.msg}`)
                    router.push('/verified')
                    break
                case 4003:
                    // 无数据 跳转
                    router.push('/')
                    break
                default:
                    // 不是正确的 code
                    errorCreate(`${dataAxios.msg}`)
                    break
            }
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求错误';
                    break
                case 401:
                    error.message = '未授权，请登录';
                    break
                case 403:
                    error.message = '拒绝访问';
                    break
                case 404:
                    error.message = `请求地址出错`;
                    break
                case 408:
                    error.message = '请求超时';
                    break
                case 500:
                    error.message = '服务器内部错误';
                    break
                case 501:
                    error.message = '服务未实现';
                    break
                case 502:
                    error.message = '网关错误';
                    break
                case 503:
                    error.message = '服务不可用';
                    break
                case 504:
                    error.message = '网关超时';
                    break
                case 505:
                    error.message = 'HTTP版本不受支持';
                    break
                default:
                    break
            }
        }
        errorLog(error)
        return Promise.reject(error)
    }
)


export default service