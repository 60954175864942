import request from '@/plugin/axios.js'


export function getSts() {
    return request({
        url: `/getSts`,
        method: 'get'
    })
}

export function Login(data) {
    return request({
        url: `/login`,
        method: 'post',
        data
    })
}


export function getClass() {
    return request({
        url: `/getClass`,
        method: 'get'
    })
}


export function getList(id) {
    return request({
        url: `/getList/${id}`,
        method: 'get'
    })
}



export function delClass(id) {
    return request({
        url: `/delClass/${id}`,
        method: 'post'
    })
}

export function addClass(data) {
    return request({
        url: `/addClass`,
        method: 'post',
        data
    })
}
export function updateClass(data, id) {
    return request({
        url: `/updateClass/${id}`,
        method: 'post',
        data
    })
}



export function addShop(data) {
    return request({
        url: `/addShop`,
        method: 'post',
        data
    })
}

export function delShop(id) {
    return request({
        url: `/delShop/${id}`,
        method: 'post'
    })
}

export function updateShop(data, id) {
    return request({
        url: `/updateShop/${id}`,
        method: 'post',
        data
    })
}




export function getDetail(id) {
    return request({
        url: `/getDetail/${id}`,
        method: 'get'
    })
}